import { useRef, useEffect, useState, useCallback } from 'react';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';
import useRandomHtmlId from 'utils/useRandomHtmlId';
import Portal from 'components/composed/Portal';
import Button, { ButtonTheme, ButtonSize } from 'components/elements/Button';
import { Props } from './types';
import styles from './styles.module.css';

const dialogTitleIdPrefix = 'dialog-title-';
const dialogDescriptionIdPrefix = 'dialog-description-';
export const dialogDataTestId = 'dialogWindow';

const Dialog = ({
  title,
  children,
  confirmText,
  cancelText,
  confirmLabel,
  onConfirm,
  onClose,
  onCancel,
  dataTestId,
  role,
}: Props) => {
  const ref = useRef<HTMLDivElement>();

  const [busy, setBusy] = useState(false);
  const dialogDescriptionId = useRandomHtmlId(dialogDescriptionIdPrefix);

  const dialogTitleId = useRandomHtmlId(dialogTitleIdPrefix);

  const handleCancel = useCallback(
    (e) => {
      e.stopPropagation();
      if (!busy) {
        if (onCancel) onCancel();
        onClose();
      }
    },
    [busy, onCancel, onClose],
  );

  const handleConfirm = useCallback(
    async (e) => {
      e.stopPropagation();
      setBusy(true);
      await onConfirm();
      onClose();
    },
    [setBusy, onClose, onConfirm],
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleConfirm(event);
      } else if (event.key === 'Escape') {
        handleCancel(event);
      }
    },
    [handleCancel, handleConfirm],
  );

  useEffect(() => {
    if (!ref.current) return;
    ref?.current?.focus();
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  return (
    <Portal element={document.body}>
      <section className={styles.container} data-testid={dataTestId}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
        <div ref={ref} onClick={handleCancel} className={styles.overlay} />
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            fallbackFocus: `div[role=${role}]`,
          }}
        >
          <div
            role={role}
            aria-modal="true"
            className={styles.box}
            aria-labelledby={dialogTitleId}
            aria-describedby={dialogDescriptionId}
            data-testid={dialogDataTestId}
          >
            <div
              className={classNames(styles.title, 'textLSemibold')}
              aria-label={title}
              id={dialogTitleId}
            >
              {title}
            </div>
            <div
              className={classNames(styles.content, 'textL')}
              id={dialogDescriptionId}
            >
              {children}
            </div>
            {confirmText ? (
              <Button
                label={confirmLabel ?? confirmText}
                onClick={handleConfirm}
                wrapperClassName={styles.confirmButton}
                size={ButtonSize.Large}
                testId="confirmBtn"
                loading={busy}
              />
            ) : null}
            {cancelText ? (
              <Button
                onKeyDown={handleCancel}
                label={cancelText}
                onClick={handleCancel}
                wrapperClassName={styles.cancelButton}
                theme={ButtonTheme.Ghost}
                size={ButtonSize.Large}
                testId="cancelBtn"
                disabled={busy}
              />
            ) : null}
          </div>
        </FocusTrap>
      </section>
    </Portal>
  );
};

Dialog.defaultProps = {
  confirmText: 'Confirm',
  confirmLabel: undefined,
  cancelText: 'Cancel',
  dataTestId: 'dialog',
  role: 'dialog',
  onCancel: undefined,
};

export default Dialog;
