export enum Actions {
  BUILD_REPORT = 'one/reporting/builder/FETCH',
  BUILD_REPORT_ERR = 'one/reporting/builder/FETCH_ERR',
  BUILD_REPORT_OK = 'one/reporting/builder/FETCH_OK',
  LOAD_TEMPLATE = 'one/reporting/builder/LOAD_TEMPLATE',
  LOAD_METADATA_OK = 'one/reporting/builder/LOAD_METADATA_OK',
  SET_REPORT = 'one/reporting/builder/SET_REPORT',
  SET_DATE_RANGE = 'one/reporting/builder/SET_DATE_RANGE',
  SET_FILTERS = 'one/reporting/builder/SET_FILTERS',
  SET_PARAMS = 'one/reporting/builder/SET_PARAMS',
  SET_FIELDS = 'one/reporting/builder/SET_FIELDS',
  SET_VIEW_OPT = 'one/reporting/builder/SET_OPT',
  SET_VIEW_OPTS = 'one/reporting/builder/SET_OPTS',
  RESET = 'one/reporting/builder/RESET',
}

export function buildReport(params): BuilderActions {
  return {
    type: Actions.BUILD_REPORT,
    params,
  };
}

export function buildReportSuccess(data, resetFilters = true): BuilderActions {
  return {
    type: Actions.BUILD_REPORT_OK,
    data,
    resetFilters,
  };
}

export function buildReportFailed(error): BuilderActions {
  return {
    type: Actions.BUILD_REPORT_ERR,
    error,
  };
}

export function loadTemplate(name, filters, params): BuilderActions {
  return {
    type: Actions.LOAD_TEMPLATE,
    name,
    filters,
    params,
  };
}

export function loadMetadataSuccess(metadata): BuilderActions {
  return {
    type: Actions.LOAD_METADATA_OK,
    metadata,
  };
}

export function setFilters(filters = {}): BuilderActions {
  return {
    type: Actions.SET_FILTERS,
    filters,
  };
}

export function setViewOption(key, value): BuilderActions {
  return {
    type: Actions.SET_VIEW_OPT,
    key,
    value,
  };
}

export function setViewOptions(options): BuilderActions {
  return {
    type: Actions.SET_VIEW_OPTS,
    options,
  };
}

export function setFields(fields): BuilderActions {
  return {
    type: Actions.SET_FIELDS,
    fields,
  };
}

export function resetState() {
  return { type: Actions.RESET };
}

export type BuilderActions =
  | {
      type: Actions.SET_FIELDS;
      fields: Record<string, any>;
    }
  | {
      type: Actions.SET_VIEW_OPTS;
      options: Record<string, any>;
    }
  | {
      type: Actions.LOAD_METADATA_OK;
      metadata: Record<string, any>;
    }
  | {
      type: Actions.BUILD_REPORT_OK;
      data: Record<string, any>;
      resetFilters: boolean;
    }
  | {
      type: Actions.SET_VIEW_OPT;
      key: string;
      value: boolean;
    }
  | {
      type: Actions.BUILD_REPORT;
      params: Record<string, any>;
    }
  | {
      type: Actions.SET_FILTERS;
      filters: Record<string, any>;
    }
  | {
      type: Actions.BUILD_REPORT_ERR;
      error: Error;
    }
  | {
      type: Actions.LOAD_TEMPLATE;
      name: string;
      filters: Record<string, any>;
      params: Record<string, any>;
    }
  | { type: Actions.RESET };
