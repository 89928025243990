import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_AUTHORIZE } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_AUTHORIZE });

const helpRoutes: AppRoute[] = [
  {
    name: 'how-to-approve-spend',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000614075-manager-supervisor-role-spend-management',
    title: 'How to approve spend',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'how-to-approve-budgets',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233965-budgets-for-admins-and-supervisors',
    title: 'How to approve budgets',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'financial-review-audit',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000614083-finance-role-spend-management',
    title: 'Financial review/audit',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
