import { isAdmin } from '@neo1/client/lib/entities/user/utils';
import { US_COUNTRY_CODE } from 'components/elements/Google/utils';
import { AppRoute } from 'config/routes/utils';

const genericHelpRoutes: AppRoute[] = [
  {
    name: 'neo1-knowledge-base',
    path: 'https://knowledge.neo1.com/support/home',
    title: 'Neo1 knowledge base',
    show: () => true,
  },
  {
    name: 'neo1-academy',
    path: 'https://knowledge.neo1.com/support/solutions/47000524538',
    title: 'Neo1 Academy',
    show: () => true,
  },
  {
    name: 'admin-set-up-guide',
    path: 'https://knowledge.neo1.com/support/solutions/47000522434',
    title: 'Admin set up guide',
    show: ({ user }) => isAdmin(user),
  },
  {
    name: 'budgets',
    path: 'https://knowledge.neo1.com/support/solutions/47000524535',
    title: 'Budgets',
    show: () => true,
  },
  {
    name: 'reporting',
    path: 'https://knowledge.neo1.com/support/solutions/47000524537',
    title: 'Reporting',
    show: () => true,
  },
  {
    name: 'virtual-cards',
    path: 'https://knowledge.neo1.com/support/solutions/47000525537',
    title: 'Virtual Cards',
    show: ({ company }) => company.countryCode === US_COUNTRY_CODE,
  },
  {
    name: 'travel-book-manage',
    path: 'https://knowledge.neo1.com/support/solutions/47000523583',
    title: 'Travel - Book & Manage',
    show: () => true,
  },
  {
    name: 'expense-submit-manage',
    path: 'https://knowledge.neo1.com/support/solutions/47000524533',
    title: 'Expense - Submit & Manage',
    show: () => true,
  },
];

export default genericHelpRoutes;
