import budgetRoutes from 'modules/Budgets/helpRoutes';
import purchaseRoutes from 'modules/Supplies/helpRoutes';
import travelHelpRoutes from 'modules/Travel/helpRoutes';
import spendRoutes from 'modules/Spend/helpRoutes';
import authorizeRoutes from 'modules/Authorize/helpRoutes';
import reconcileRoutes from 'modules/Reconciliation/helpRoutes';
import receiptsRoutes from 'modules/Receipts/helpRoutes';
import reportsRoutes from 'modules/Reports/helpRoutes';
import settingsRoutes from 'modules/Settings/helpRoutes';

const contextualHelpRoutes = [
  ...budgetRoutes,
  ...purchaseRoutes,
  ...travelHelpRoutes,
  ...spendRoutes,
  ...authorizeRoutes,
  ...reconcileRoutes,
  ...receiptsRoutes,
  ...reportsRoutes,
  ...settingsRoutes,
];

export default contextualHelpRoutes;
