import { isAdmin } from '@neo1/client/lib/entities/user/utils';
import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_TRAVEL } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_TRAVEL });

const helpRoutes: AppRoute[] = [
  {
    name: 'Book & manage',
    path: 'https://knowledge.neo1.com/support/solutions/47000523583',
    title: 'Book & manage',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'user-profile',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000778611',
    title: 'User profile',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && !isAdmin(user),
  },
  {
    name: 'book-travel-admin-arranger',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000778608',
    title: 'Book travel (admin/arranger)',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && isAdmin(user),
  },
  {
    name: 'travel-payment-methods',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000784170',
    title: 'Travel payment methods',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && !isAdmin(user),
  },
  {
    name: 'travel-policy',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000784217',
    title: 'Travel policy',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && isAdmin(user),
  },
  {
    name: 'book-travel',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000778604',
    title: 'Book travel',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'change-modify-cancel-a-trip',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000787852',
    title: 'Change/modify/cancel a trip',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'travel-invoices',
    path: 'https://knowledge.neo1.com/support/solutions/folders/47000782095',
    title: 'Travel invoices',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'travel-reports',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001232158-travel-reports',
    title: 'Travel reports',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
