export enum RpcMethodName {
  Login = 'login',
  LoginSso = 'login_sso',
  LoginToken = 'login_token',
  Logout = 'logout',
  NewDomain = 'new_domain',
  UpdateDomain = 'update_domain',
  UpdateDomainBranding = 'update_domain_branding',
  UpdateDomainLogo = 'update_domain_logo',
  DeleteDomainLogo = 'delete_domain_logo',
  GetDomain = 'get_domain',
  GetDomains = 'get_domains',
  NewCompany = 'new_company',
  GetCompanies = 'get_companies',
  LinkCompany = 'link_company',
  UnlinkCompany = 'unlink_company',
  GetCompany = 'get_company',
  UpdateCompany = 'update_company',
  SyncCompanyTravelIntegration = 'sync_company_travel_integration',
  NewCompanyUser = 'new_company_user',
  NewDomainUser = 'new_domain_user',
  NewSystemUser = 'new_system_user',
  RecallUser = 'recall_user',
  UpdateUser = 'update_user',
  UpdateUserPassword = 'update_user_password',
  ResetUserPassword = 'reset_user_password',
  ConfirmResetUserPassword = 'confirm_reset_user_password',
  GetUserByLogin = 'get_user_by_login',
  GetUserById = 'get_user_by_id',
  ActivateUser = 'activate_user',
  GetCompanyUsers = 'get_company_users',
  GetCompanyGroupUsers = 'get_company_group_users',
  GetCompanyFundingApprovers = 'get_company_funding_approvers',
  GetActiveDelegationsByRecipient = 'get_active_delegations_by_recipient',
  GetNotExpiredDelegations = 'get_not_expired_delegations',
  RequestAuthenticationToken = 'request_authentication_token',
  GetAuthenticationTokens = 'get_authentication_tokens',
  RevokeAuthenticationToken = 'revoke_authentication_token',
  GetActiveUsersReport = 'get_active_users_report',
  GetUser = 'get_user',
  GetCurrencies = 'get_currencies',
  ReloadExchangeRates = 'reload_exchange_rates',
  GetTxPaymentInstrument = 'get_tx_payment_instrument',
  CreatePersonalCashTx = 'create_personal_cash_tx',
  UpdateTx = 'update_tx',
  UpdateTxs = 'update_txs',
  GetTx = 'get_tx',
  DeleteTx = 'delete_tx',
  SubmitTx = 'submit_tx',
  GetTxsForSupervision = 'get_txs_for_supervision',
  ApproveTx = 'approve_tx',
  ApproveTxs = 'approve_txs',
  RejectTx = 'reject_tx',
  RejectTxs = 'reject_txs',
  ReviewTx = 'review_tx',
  ReviewTxs = 'review_txs',
  CommentTx = 'comment_tx',
  SetSegment = 'set_segment',
  SetSegmentValue = 'set_segment_value',
  SetSegmentUserDefault = 'set_segment_user_default',
  SetSegmentCreditCardDefault = 'set_segment_credit_card_default',
  GetSegments = 'get_segments',
  GetSegmentValues = 'get_segment_values',
  GetSegmentDefaults = 'get_segment_defaults',
  DelSegment = 'del_segment',
  DelSegmentValue = 'del_segment_value',
  DelSegmentUserDefault = 'del_segment_user_default',
  DelSegmentCreditCardDefault = 'del_segment_credit_card_default',
  DelSegmentNatureDefault = 'del_segment_automation_rule_default',
  GetTaxes = 'get_taxes',
  SetTax = 'set_tax',
  DelTax = 'del_tax',
  SetExnConfiguration = 'set_exn_configuration',
  DelExnConfiguration = 'del_exn_configuration',
  GetExnConfigurations = 'get_exn_configurations',
  SetPersonalCashAccount = 'set_personal_cash_account',
  GetAccounts = 'get_accounts',
  GetAccount = 'get_account',
  GetAccountReport = 'get_account_report',
  AssignCreditCard = 'assign_credit_card',
  SetPersonalCashWallet = 'set_personal_cash_wallet',
  GetAccountPaymentInstruments = 'get_account_payment_instruments',
  GetUserPaymentInstruments = 'get_user_payment_instruments',
  SetExpenseConfiguration = 'set_expense_configuration',
  GetExpenseConfiguration = 'get_expense_configuration',
  ExtractTx = 'extract_tx',
  GetAccountTx = 'get_account_tx',
}
