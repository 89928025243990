import { AppMenuRoute } from 'config/routes/utils';
import { generatePath } from 'react-router';
import async from 'utils/wrapAsync';
import { ROUTE_NAME_ADMIN_SEARCH, ROUTE_PATH_ADMIN_SEARCH } from './constants';

const route: AppMenuRoute = {
  path: ROUTE_PATH_ADMIN_SEARCH,
  name: ROUTE_NAME_ADMIN_SEARCH,
  exact: true,
  component: async(
    () =>
      import(
        /* webpackChunkName: "adminSearch" */
        '.'
      ),
  ),
  menu: {
    iconClass: 'search',
    path: generatePath(ROUTE_PATH_ADMIN_SEARCH),
  },
};

export default route;
