import { defineMessages } from 'react-intl';

const messages = defineMessages({
  contactAvailableMessage: {
    id: 'App_HelpMenu_Contact_contactAvailableMessage',
    defaultMessage: 'For help using Neo1 at your business please contact:',
  },
  contactToFillMessage: {
    id: 'App_HelpMenu_Contact_contactToFillMessage',
    defaultMessage:
      'Include the contact details to address any issues users may face within Neo1.',
  },
  editContactBtn: {
    id: 'App_HelpMenu_Contact_editContactBtn',
    defaultMessage: 'Edit contact information',
  },
  addContactBtn: {
    id: 'App_HelpMenu_Contact_addContactBtn',
    defaultMessage: 'Add contact information',
  },
});

export default messages;
