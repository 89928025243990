import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App_HelpMenu_title',
    defaultMessage: 'How can we help you?',
  },
  goToLabel: {
    id: 'App_HelpMenu_goToLabel',
    defaultMessage: 'Go to {title}',
  },
});

export default messages;
