import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_REPORTS } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_REPORTS });

const helpRoutes: AppRoute[] = [
  {
    name: 'reports-overview',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001266375-overview-of-reports',
    title: 'Reports overview',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'predefined-spend-reports',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000615732-predefined-spend-reports',
    title: 'Predefined spend reports',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'travel-reports',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001232158-travel-reports',
    title: 'Travel reports',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'budget-reporting',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233966-budget-reporting',
    title: 'Budget reporting',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'spend-report-builder',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000615739-spend-report-builder',
    title: 'Spend report builder',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
