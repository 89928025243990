import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import classNames from 'classnames';
import { ReactNode } from 'react';
import useGoback from '../useGoBack';
import About from './About';
import Actions from './Address/Actions';
import GetStarted, { GetStartedActions } from './GetStarted';
import { OwnersActions } from './Owners';
import styles from './StepView.module.css';

export const GetStartedView = ({ className }: { className: string }) => (
  <div className={classNames(styles.container, className)}>
    <GetStarted />
    <GetStartedActions className={styles.startBtn} />
  </div>
);

export const AboutView = ({
  className,
  onBack,
}: {
  className: string;
  onBack: (values: CompanyActivationData) => void;
}) => {
  const { handleOnBack } = useGoback(onBack);

  return (
    <div className={classNames(styles.container, className)}>
      <About />
      <Actions className={styles.btn} onGoBack={handleOnBack} />
    </div>
  );
};

export const OwnersView = ({
  className,
  isSubmitDisabled,
  isShowingOwnerValidationResult,
  onBackOwnerAddressValidation,
  onBack,
  children,
}: {
  className: string;
  isSubmitDisabled?: boolean;
  isShowingOwnerValidationResult: boolean;
  onBackOwnerAddressValidation: () => void;
  onBack: (values: CompanyActivationData) => void;
  children: ReactNode;
}) => {
  const { handleOnBack } = useGoback(onBack);
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.ownerViewWrapper}>{children}</div>
      <OwnersActions
        className={styles.btn}
        isSubmitDisabled={isSubmitDisabled}
        isShowingOwnerValidationResult={isShowingOwnerValidationResult}
        onBackOwnerAddressValidation={onBackOwnerAddressValidation}
        onGoBack={handleOnBack}
      />
    </div>
  );
};

OwnersView.defaultProps = {
  isSubmitDisabled: false,
};
