import classNames from 'classnames';
import Icon, { IconName } from 'components/elements/Icon';
import { ReactNode } from 'react';
import isEnterOrSpace from 'utils/keyboardEventHelper';
import Button, { ButtonTheme } from '../Button';
import styles from './RadioCard.module.css';

export interface RadioCardAction {
  callable?(): void;
  label: string;
  link?: string;
}

interface Props<T> {
  action?: RadioCardAction;
  body?: ReactNode;
  dataTestId?: string;
  description?: string;
  icon?: IconName;
  isCentered?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isSelected?: boolean;
  onSelect(value: T): void;
  subtitle?: string;
  title?: string;
  value: T;
}

function RadioCard<T>({
  action,
  body,
  dataTestId,
  description,
  icon,
  isCentered,
  isDisabled,
  isError,
  isSelected,
  onSelect,
  subtitle,
  title,
  value,
}: Props<T>) {
  const hasAction = action && (action.callable || action.link);
  // A card with an icon can only be centered
  const centered = !!icon || isCentered;

  return (
    <div
      className={classNames(
        styles.container,
        centered && styles.containerCentered,
        isSelected && styles.selected,
        isDisabled && styles.disabled,
        isError && styles.error,
      )}
      onClick={() => {
        onSelect(value);
      }}
      onKeyUp={(ev) => {
        if (isEnterOrSpace(ev)) {
          onSelect(value);
        }
      }}
      role="radio"
      aria-checked={isSelected}
      aria-disabled={isDisabled}
      tabIndex={isDisabled ? -1 : 0}
      data-value={value}
      data-testid={dataTestId}
    >
      <div
        className={classNames(
          styles.contentWrapper,
          centered && styles.contentWrapperCentered,
        )}
      >
        {icon && (
          <div className={classNames(styles.iconWrapper)}>
            <Icon name={icon} />
          </div>
        )}
        {(title || subtitle || description) && (
          <div className={styles.textWrapper}>
            {title && (
              <div
                className={classNames(
                  'textLSemibold',
                  centered && styles.textCentered,
                )}
              >
                {title}
              </div>
            )}
            {(subtitle || description || body) && (
              <div className={classNames(styles.subtitleWrapper)}>
                {subtitle && (
                  <div
                    className={classNames(
                      'textMSemibold',
                      centered && styles.textCentered,
                    )}
                  >
                    {subtitle}
                  </div>
                )}
                {description ? (
                  <div
                    className={classNames(
                      'textM',
                      centered && styles.textCentered,
                    )}
                  >
                    {description}
                  </div>
                ) : (
                  body && body
                )}
              </div>
            )}
          </div>
        )}
        {hasAction && (
          <>
            {action.link ? (
              <Button
                label={action.label}
                theme={ButtonTheme.Ghost}
                disabled={isDisabled}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(action.link, '_blank');
                }}
              />
            ) : (
              action.callable && (
                <Button
                  label={action.label}
                  theme={ButtonTheme.Outlined}
                  disabled={isDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    action.callable();
                  }}
                />
              )
            )}
          </>
        )}
      </div>
    </div>
  );
}

RadioCard.defaultProps = {
  action: undefined,
  body: undefined,
  dataTestId: undefined,
  description: undefined,
  icon: undefined,
  isCentered: false,
  isDisabled: false,
  isError: false,
  isSelected: false,
  subtitle: undefined,
  title: undefined,
};

export default RadioCard;
