import { Addr } from '@neo1/core/utils/addressValidation';
import AddressBlock from '../AddressBlock';
import styles from './UnconfirmedAddress.module.css';

type Props = {
  address: Addr;
};

const UnconfirmedAddress = ({ address }: Props) => {
  if (!address) {
    return null;
  }
  return (
    <div className={styles.container}>
      <h4 className={styles.title} data-testid="unconfirmedAddressTitle">
        Are you sure this information is correct?
      </h4>
      <AddressBlock address={address} testId="unconfirmedAddress" />
    </div>
  );
};

export default UnconfirmedAddress;
