import classNames from 'classnames';
import FieldWrapper, {
  FieldWrapperProps,
} from 'components/elements/form/FieldWrapper';
import { IconName } from 'components/elements/Icon';
import RadioCard, { RadioCardAction } from 'components/elements/RadioCard';
import { ReactNode, useState } from 'react';
import styles from './RadioCards.module.css';

export interface RadioCardsOptions<T> {
  action?: RadioCardAction;
  body?: ReactNode;
  dataTestId?: string;
  description?: string;
  icon?: IconName;
  subtitle?: string;
  title?: string;
  disabled?: boolean;
  value: T;
}

interface Props<T> extends Omit<FieldWrapperProps, 'noBorder' | 'children'> {
  defaultValue?: T;
  isCentered?: boolean;
  isDisabled?: boolean;
  isVertical?: boolean;
  onChange?(value: T): void;
  options: RadioCardsOptions<T>[];
}

function RadioCards<T>({
  defaultValue,
  isDisabled,
  isCentered,
  isVertical,
  onChange,
  options,
  ...fieldWrapperProps
}: Props<T>) {
  const [currentOption, setCurrentOption] = useState<T>(defaultValue);
  const onSelect = (value) => {
    setCurrentOption(value);
    onChange(value);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FieldWrapper {...fieldWrapperProps} noBorder>
      <div
        className={classNames(styles.container, isVertical && styles.vertical)}
        role="radiogroup"
      >
        {options.map(
          ({
            action,
            body,
            dataTestId,
            description,
            icon,
            subtitle,
            title,
            value,
            disabled,
          }) => (
            <RadioCard
              action={action}
              body={body}
              description={description}
              icon={icon}
              isCentered={isCentered}
              isDisabled={isDisabled || disabled}
              isError={fieldWrapperProps.isInvalid}
              isSelected={value === currentOption}
              onSelect={(v) => {
                if (!isDisabled) {
                  onSelect(v);
                }
              }}
              subtitle={subtitle}
              title={title}
              value={value}
              dataTestId={dataTestId}
              key={value.toString()}
            />
          ),
        )}
      </div>
    </FieldWrapper>
  );
}

RadioCards.defaultProps = {
  defaultValue: null,
  isCentered: false,
  isDisabled: false,
  isVertical: false,
  onChange: undefined,
};

export default RadioCards;
