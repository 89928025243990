import { isEmail } from '@neo1/client/lib/entities/common/strings';
import EditForm from 'components/composed/EditForm';
import FormField from 'components/elements/form/Field';
import TextInput from 'components/elements/form/fields/TextInput';
import { FormikErrors } from 'formik';
import {
  notifyError,
  notifySuccess,
} from 'modules/App/redux/notifications/toaster/thunks';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { save as saveCompany } from 'redux/company/thunks';
import messages from './messages';

type Values = {
  name: string;
  email: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ContactModalForm = ({ isOpen, onClose }: Props) => {
  const intl = useIntl();
  const company = useSelector(selectActingCompany);
  const dispatch = useDispatch();

  const initialValues: Values = {
    name: company.helpContactName,
    email: company.helpContactEmail,
  };

  const validate = (values: Values) => {
    const errors: FormikErrors<Values> = {};

    if (values.email && !isEmail(values.email)) {
      errors.email = intl.formatMessage(messages.emailError);
    }

    return errors;
  };

  const onSubmit = async (values: Values) => {
    try {
      await dispatch(
        saveCompany({
          company_id: company.id,
          help_contact_name: values.name,
          help_contact_email: values.email,
        }),
      );
      dispatch(notifySuccess('Changes successfully saved'));
      onClose();
    } catch (err) {
      dispatch(notifyError(`Changes could not be saved. ${err.message}`));
    }
  };

  return (
    <EditForm
      isOpen={isOpen}
      title={
        company.helpContactName || company.helpContactEmail
          ? intl.formatMessage(messages.editModalTitle)
          : intl.formatMessage(messages.addModalTitle)
      }
      onSubmit={onSubmit}
      onClose={onClose}
      initialValues={initialValues}
      validate={validate}
      enableReinitialize
    >
      <FormField title={intl.formatMessage(messages.nameLabel)} name="name">
        <TextInput maxLength={80} />
      </FormField>
      <FormField title={intl.formatMessage(messages.emailLabel)} name="email">
        <TextInput maxLength={255} />
      </FormField>
    </EditForm>
  );
};

export default ContactModalForm;
