import get from 'lodash/get';

import { TableColumns, OnRowClick, GetRowActions, TableProps } from '../types';
import CardItem from './CardItem';
import styles from './CardList.module.css';

type Props = {
  columns: TableColumns;
  rows: Array<Object>;
  rowKeyProp: PropertyKey;
  renderRowDetail: TableProps['rowDetail']['render'];
  onRowClick?: OnRowClick;
  getRowActions?: GetRowActions;
  isRowSelectable: (row: Object) => boolean;
  hideSelectCheckbox: TableProps['hideSelectCheckbox'];
};

const defaultProps: Partial<Props> = {
  onRowClick: undefined,
  getRowActions: undefined,
};

const CardList = ({
  columns,
  rows,
  rowKeyProp,
  renderRowDetail,
  onRowClick,
  getRowActions,
  isRowSelectable,
  hideSelectCheckbox,
}: Props) => (
  <ol className={styles.container}>
    {rows.map((row, rowIndex) => {
      const id = get(row, rowKeyProp, rowIndex);
      return (
        <CardItem
          key={id}
          id={id}
          rowIndex={rowIndex}
          columns={columns}
          row={row}
          onClick={onRowClick}
          rowActions={getRowActions ? getRowActions(row) : []}
          rows={rows}
          renderDetail={renderRowDetail}
          isRowSelectable={isRowSelectable}
          hideSelectCheckbox={hideSelectCheckbox}
        />
      );
    })}
  </ol>
);

CardList.defaultProps = defaultProps;

export default CardList;
