import { isAdmin } from '@neo1/client/lib/entities/user/utils';
import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_PURCHASE_BUDGETS } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_PURCHASE_BUDGETS });

const helpRoutes: AppRoute[] = [
  {
    name: 'understanding-budgets',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233935-understanding-budgets',
    title: 'Understanding Budgets',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'creating-a-budget',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233937-creating-a-budget',
    title: 'Creating a Budget',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'tagging-transactions-and-tracking-budget-spend',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233940-tagging-transactions-and-tracking-budget-spend',
    title: 'Tracking Budget Spend',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'budgets-for-admins-and-supervisors',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233965-budgets-for-admins-and-supervisors',
    title: 'Admins and Supervisors',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && isAdmin(user),
  },
  {
    name: 'budget-reporting',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001233966-budget-reporting',
    title: 'Budget Reporting',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
