import get from 'lodash/get';

import { TableColumn } from './types';

type Record = Object | Array<any>;

const formatValue = (
  record: Object | Array<any>,
  column: TableColumn,
  index: number,
  records: Record[],
  rowIndex: number,
) => {
  if (!column.prop) {
    return column.render(null, record, column, records, rowIndex);
  }

  let value = Array.isArray(record) ? record[index] : get(record, column.prop);

  if (column.render) {
    value = column.render(value, record, column, records, rowIndex);
  }

  if (value === null || typeof value === 'undefined' || value === '') {
    value = column.default ?? '';
  }

  return value;
};

export default formatValue;
