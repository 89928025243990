import { Addr } from '@neo1/core/utils/addressValidation';
import RadioCards from 'components/elements/form/fields/RadioCards';
import AddressBlock from '../AddressBlock';
import styles from './ReplacedAddressFields.module.css';

export enum VALUE {
  REPLACED_ADDRESS = 'replaced',
  ORIGINAL_ADDRESS = 'original',
}

type Props = {
  address: Addr;
  replacedAddress: Addr;
  onBack: () => void;
  onChange: (value: VALUE) => void;
};

const ReplacedAddressFields = ({
  address,
  replacedAddress,
  onBack,
  onChange,
}: Props) => {
  if (!address || !replacedAddress) {
    return null;
  }
  return (
    <div className={styles.container}>
      <h4 className={styles.title} data-testid="replacedAddressTitle">
        Select the option to confirm your choice
      </h4>
      <RadioCards
        onChange={onChange}
        options={[
          {
            value: VALUE.ORIGINAL_ADDRESS,
            title: 'You entered:',
            dataTestId: 'addressValues',
            body: <AddressBlock address={address} />,
            action: {
              label: 'Edit address',
              callable: onBack,
            },
          },
          {
            value: VALUE.REPLACED_ADDRESS,
            title: 'Suggested address:',
            dataTestId: 'correctedAddressValues',
            body: (
              <AddressBlock
                address={replacedAddress}
                replacedFields={Object.keys(replacedAddress).reduce(
                  (res, k) => {
                    if (replacedAddress[k] !== address[k]) {
                      return [...res, k];
                    }
                    return res;
                  },
                  [],
                )}
              />
            ),
          },
        ]}
        defaultValue={VALUE.REPLACED_ADDRESS}
        isVertical
      />
    </div>
  );
};

export default ReplacedAddressFields;
