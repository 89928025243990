import { ReactNode, useCallback, useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import {
  Formik,
  FormikHelpers,
  Form,
  useFormikContext,
  FormikProps,
} from 'formik';
import Button, { ButtonTheme, ButtonSize } from 'components/elements/Button';
import useConfirmDialog from '../MultiStepModalForm/useConfirmDialog';
import ModalFormik from './ModalFormik';
import { EditFormProps } from './types';
import styles from './EditForm.module.css';

export interface InnerEditFormProps
  extends Omit<
    EditFormProps,
    | 'validate'
    | 'onSubmit'
    | 'initialValues'
    | 'onSubmitError'
    | 'enableReinitialize'
  > {
  children: ReactNode | ((formikProps: FormikProps<any>) => ReactNode);
  isSubmitting: boolean;
  dirty: boolean;
}

function InnerEditForm({
  isSubmitting,
  dirty,
  onClose,
  children,
  className,
  contentClassName,
  isOpen,
  isReadOnly,
  isSubmitDisabled,
  footerExtra,
  renderFooterExtra,
  noFooter,
  noScroll,
  renderFooter,
  showDoneButton,
  title,
  noAutoFocus,
  headerClassName,
  showHeader,
  isLoading,
  testId,
  confirmBtnLabel,
  withBackground,
}: InnerEditFormProps) {
  const formikContext = useFormikContext();

  const { values, setStatus } = formikContext;

  const { confirmDialog, handleOnClose } = useConfirmDialog(onClose, isOpen);

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Esc' || event.key === 'Escape') {
        event.preventDefault();
        handleOnClose(values, dirty);
      }
    },
    [dirty, handleOnClose, values],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEscape);
    return () => window.removeEventListener('keydown', onEscape);
  }, [onEscape]);

  const getFooter = () => {
    if (noFooter) {
      return null;
    }

    if (renderFooter) {
      return renderFooter(values, isSubmitting);
    }

    if (!showDoneButton) {
      return null;
    }

    const elements = [
      <Button
        key="confirm"
        label={confirmBtnLabel || 'Confirm'}
        type="submit"
        loading={isSubmitting}
        disabled={isSubmitDisabled}
        size={ButtonSize.Large}
        testId="submitFormBtn"
      />,
    ];

    if (isFunction(onClose)) {
      elements.unshift(
        <Button
          key="cancel"
          label="Cancel"
          onClick={() => handleOnClose(values, dirty)}
          disabled={isSubmitting}
          theme={ButtonTheme.Ghost}
          size={ButtonSize.Large}
          testId="cancelFormBtn"
        />,
      );
    }

    if (footerExtra) {
      elements.unshift(
        <div key="footerExtra" className={styles.footerExtraWrapper}>
          {footerExtra}
        </div>,
      );
    }

    if (renderFooterExtra) {
      elements.unshift(
        <div key="renderFooterExtra" className={styles.footerExtraWrapper}>
          {renderFooterExtra(formikContext)}
        </div>,
      );
    }

    return elements;
  };

  return (
    <>
      <Form>
        <ModalFormik
          className={className}
          contentClassName={contentClassName}
          isOpen={isOpen}
          isReadOnly={isReadOnly}
          noScroll={noScroll}
          title={title}
          noAutoFocus={noAutoFocus}
          headerClassName={headerClassName}
          showHeader={showHeader}
          error={formikContext?.status?.apiError || ''}
          submitting={isSubmitting}
          dirty={dirty}
          onClose={() => (!isSubmitting ? handleOnClose(values, dirty) : null)}
          footer={getFooter()}
          isLoading={isLoading}
          testId={testId}
          onDismissInfoBox={() => setStatus({ apiError: '' })}
          withBackground={withBackground}
        >
          {children}
        </ModalFormik>
      </Form>
      {confirmDialog}
    </>
  );
}

export default function EditForm({
  initialValues,
  onSubmit,
  onSubmitError,
  validate,
  enableReinitialize,
  children,
  className,
  contentClassName,
  isOpen,
  isReadOnly,
  isSubmitDisabled,
  footerExtra,
  renderFooterExtra,
  noFooter,
  noScroll,
  onClose,
  renderFooter,
  showDoneButton,
  title,
  noAutoFocus,
  headerClassName,
  showHeader,
  isLoading,
  testId,
  confirmBtnLabel,
  withBackground,
  initialTouched,
  alwaysDirty,
}: EditFormProps) {
  if (!isOpen) {
    return null;
  }
  type Values = typeof initialValues;

  const submit = async (values: Values, actions: FormikHelpers<Values>) => {
    const { setStatus } = actions;

    setStatus({ apiError: '' });

    try {
      await onSubmit(values, actions);
    } catch (error) {
      setStatus({ apiError: error.message });
      if (isFunction(onSubmitError)) {
        onSubmitError(error, actions);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      initialStatus={{ apiError: '' }}
      enableReinitialize={enableReinitialize}
      initialTouched={initialTouched}
    >
      {({ isSubmitting, dirty }) => (
        <InnerEditForm
          className={className}
          contentClassName={contentClassName}
          isOpen={isOpen}
          isReadOnly={isReadOnly}
          isSubmitDisabled={isSubmitDisabled}
          footerExtra={footerExtra}
          renderFooterExtra={renderFooterExtra}
          noFooter={noFooter}
          noScroll={noScroll}
          onClose={onClose}
          renderFooter={renderFooter}
          showDoneButton={showDoneButton}
          title={title}
          noAutoFocus={noAutoFocus}
          headerClassName={headerClassName}
          showHeader={showHeader}
          isSubmitting={isSubmitting}
          dirty={dirty || alwaysDirty}
          isLoading={isLoading}
          testId={testId}
          confirmBtnLabel={confirmBtnLabel}
          withBackground={withBackground}
        >
          {children}
        </InnerEditForm>
      )}
    </Formik>
  );
}

EditForm.defaultProps = {
  dirty: false,
  showDoneButton: true,
  isOpen: false,
  isSubmitDisabled: false,
  noAutoFocus: false,
  isLoading: false,
  testId: undefined,
  withBackground: true,
  alwaysDirty: false,
};
