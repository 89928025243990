import { defineMessage } from 'react-intl';

const messages = {
  stepsTitles: [
    defineMessage({
      id: 'Activate__stepTitles__GetStarted',
      defaultMessage: 'Get started',
    }),
    defineMessage({
      id: 'Activate__stepTitles__About',
      defaultMessage: 'Business information',
    }),
    defineMessage({
      id: 'Activate__stepTitles__Address',
      defaultMessage: 'Business address',
    }),
    defineMessage({
      id: 'Activate__stepTitles__Owners',
      defaultMessage: 'Business owners',
    }),
    defineMessage({
      id: 'Activate__stepTitles__Send',
      defaultMessage: 'Account preferences',
    }),
    defineMessage({
      id: 'Activate__stepTitles__finish',
      defaultMessage: 'Request sent',
    }),
  ],
  getStarted: {
    id: 'Activate__stepTitles__GetStarted',
    defaultMessage: 'Get started',
  },
  about: {
    id: 'Activate__stepTitles__About',
    defaultMessage: 'Business information',
  },
  address: {
    id: 'Activate__stepTitles__Address',
    defaultMessage: 'Business address',
  },
  owners: {
    id: 'Activate__stepTitles__Owners',
    defaultMessage: 'Business owners',
  },
  finish: {
    id: 'Activate__stepTitles__finish',
    defaultMessage: 'Request sent',
  },
  modalTitle: defineMessage({
    id: 'Activate__modalTitle',
    defaultMessage: 'Business activation request',
  }),
  sidePanelTooltipText: defineMessage({
    id: 'Activate__sidePanelTooltipText',
    defaultMessage: 'Why do you need to provide this information ?',
  }),
  side: {
    getStarted: {
      title: defineMessage({
        id: 'Activate__side__getStarted__title',
        defaultMessage: 'Welcome to Neo1!',
      }),
      desc: defineMessage({
        id: 'Activate__side__getStarted__desc',
        defaultMessage:
          'Please complete this setup to activate your business in Neo1.',
      }),
      info: defineMessage({
        id: 'Activate__side__getStarted__info',
        defaultMessage:
          'Neo1 is backed by American Express Global Business Travel.',
      }),
      secondInfo: defineMessage({
        id: 'Activate__side__getStarted__info2',
        defaultMessage:
          ' We operate under various laws, including those that aim to prevent fraud and money laundering.  ',
      }),
      thirdInfo: defineMessage({
        id: 'Activate__side__getStarted__info3',
        defaultMessage: 'These laws require us to ask additional questions.',
      }),
    },
    about: {
      title: defineMessage({
        id: 'Activate__side__about__title',
        defaultMessage: 'Business information',
      }),
      desc: defineMessage({
        id: 'Activate__side__about__desc',
        defaultMessage: 'Please provide us details about your business',
      }),
      info: null,
    },
    address: {
      title: defineMessage({
        id: 'Activate__side__addr__title',
        defaultMessage: 'Registered business address',
      }),
      desc: defineMessage({
        id: 'Activate__side__addr__desc',
        defaultMessage: 'Tell us where your business is located',
      }),
      info: null,
    },
    owners: {
      title: defineMessage({
        id: 'Activate__side__own__title',
        defaultMessage: "Business's beneficial owners",
      }),
      desc: defineMessage({
        id: 'ActivateCompany__Owners__infoTitle',
        defaultMessage:
          'Anti-money laundering and know your customer initiatives require us to verify the identity of the businesses and individuals we are doing business with. Our customer due diligence process screens the requested information against several governmental watchlists and PEP (politically exposed persons) databases.',
      }),
      info: defineMessage({
        id: 'ActivateCompany__Owners__infoP1',
        defaultMessage:
          'Note only individuals owning 25% stake or more are concerned by these checks.',
      }),
      secondInfo: defineMessage({
        id: 'ActivateCompany__Owners__infoP3',
        defaultMessage:
          "If you are one of the business's owners, and own at least 25% of the business, please include yourself in the list.",
      }),
    },
    neo1Features: {
      title: defineMessage({
        id: 'Activate__side__neo1Features__title',
        defaultMessage: 'Tell us your priorities',
      }),
      desc: defineMessage({
        id: 'Activate__side__neo1Features__info',
        defaultMessage:
          'In order to preset your account we need you to express your needs.',
      }),
      secondInfo: defineMessage({
        id: 'Activate__side__neo1Features__info2',
        defaultMessage:
          'Please adjust the parameters on the right hand side to define which situation reflect yours.',
      }),
      thirdInfo: defineMessage({
        id: 'Activate__side__neo1Features__info3',
        defaultMessage:
          'These settings can be changed later in Neo1 from the Account Settings section as your business needs evolve.',
      }),
      fourthInfo: defineMessage({
        id: 'Activate__side__neo1Features__info4',
        defaultMessage:
          'Feel free to contact us at contact@neo1.com if you want more information about our services.',
      }),
    },
    finish: {
      title: defineMessage({
        id: 'Activate__side__finish__title',
        defaultMessage:
          'Congratulations, your business activation request has been sent!',
      }),
      desc: defineMessage({
        id: 'Activate__side__finish__desc',
        defaultMessage:
          'Please tell us in this 30 second survey how was your registration experience',
      }),
      info: null,
    },
  },
  footer: {
    btnBack: defineMessage({
      id: 'Activate__footer__btnBack',
      defaultMessage: 'Back',
    }),
    btnNext: defineMessage({
      id: 'Activate__footer__btnNext',
      defaultMessage: 'Save and continue',
    }),
    btnSendRequest: defineMessage({
      id: 'Activate__footer__btnFinish',
      defaultMessage: 'Send the request',
    }),
    btnClose: defineMessage({
      id: 'Activate__footer__btnClose',
      defaultMessage: 'Close this wizard',
    }),
    btnStart: defineMessage({
      id: 'Activate__footer__btnStart',
      defaultMessage: "Let's start",
    }),
    btnContinue: defineMessage({
      id: 'Activate__footer__btnContinue',
      defaultMessage: 'Continue',
    }),
    btnSaveOwner: defineMessage({
      id: 'Activate__footer__btnSaveOwner',
      defaultMessage: 'Save',
    }),
    btnCancel: defineMessage({
      id: 'Activate__footer__btnCancel',
      defaultMessage: 'Cancel',
    }),
    getStartedMessage: defineMessage({
      id: 'Activate__footer__getStartedMsg',
      defaultMessage: 'This will take you about 3 minutes !',
    }),
  },
};

export default messages;
