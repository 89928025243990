import { createContext, useContext, ReactNode, useReducer } from 'react';

type Props = {
  children: ReactNode;
};

type State = {
  isProfileMenuOpen: boolean;
  isNavMenuOpen: boolean;
  isHelpMenuOpen: boolean;
};

const defaultState: State = {
  isProfileMenuOpen: false,
  isNavMenuOpen: false,
  isHelpMenuOpen: false,
};

type Action =
  | { type: 'openProfileMenu' }
  | { type: 'closeProfileMenu' }
  | { type: 'toggleProfileMenu' }
  | { type: 'openNavMenu' }
  | { type: 'closeNavMenu' }
  | { type: 'toggleNavMenu' }
  | { type: 'openHelpMenu' }
  | { type: 'closeHelpMenu' }
  | { type: 'toggleHelpMenu' }
  | { type: 'closeAllMenus' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'openProfileMenu':
      return {
        isProfileMenuOpen: true,
        isNavMenuOpen: false,
        isHelpMenuOpen: false,
      };
    case 'closeProfileMenu':
      return {
        ...state,
        isProfileMenuOpen: false,
      };
    case 'toggleProfileMenu':
      return {
        isProfileMenuOpen: !state.isProfileMenuOpen,
        isNavMenuOpen: !state.isProfileMenuOpen ? false : state.isNavMenuOpen,
        isHelpMenuOpen: !state.isProfileMenuOpen ? false : state.isHelpMenuOpen,
      };
    case 'openNavMenu':
      return {
        isProfileMenuOpen: false,
        isNavMenuOpen: true,
        isHelpMenuOpen: false,
      };
    case 'closeNavMenu':
      return {
        ...state,
        isNavMenuOpen: false,
      };
    case 'toggleNavMenu':
      return {
        isProfileMenuOpen: !state.isNavMenuOpen
          ? false
          : state.isProfileMenuOpen,
        isNavMenuOpen: !state.isNavMenuOpen,
        isHelpMenuOpen: !state.isNavMenuOpen ? false : state.isHelpMenuOpen,
      };
    case 'openHelpMenu':
      return {
        isProfileMenuOpen: false,
        isNavMenuOpen: false,
        isHelpMenuOpen: true,
      };
    case 'closeHelpMenu':
      return {
        ...state,
        isHelpMenuOpen: false,
      };
    case 'toggleHelpMenu':
      return {
        isProfileMenuOpen: !state.isHelpMenuOpen
          ? false
          : state.isProfileMenuOpen,
        isNavMenuOpen: !state.isHelpMenuOpen ? false : state.isNavMenuOpen,
        isHelpMenuOpen: !state.isHelpMenuOpen,
      };
    case 'closeAllMenus':
      return {
        isProfileMenuOpen: false,
        isNavMenuOpen: false,
        isHelpMenuOpen: false,
      };
    default: {
      throw Error('Unknown action.');
    }
  }
};

export const LayoutContext = createContext<any>(defaultState);

export const LayoutContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const actions = {
    openProfileMenu: () => {
      dispatch({ type: 'openProfileMenu' });
    },
    closeProfileMenu: () => {
      dispatch({ type: 'closeProfileMenu' });
    },
    toggleProfileMenu: () => {
      dispatch({ type: 'toggleProfileMenu' });
    },
    openNavMenu: () => {
      dispatch({ type: 'openNavMenu' });
    },
    closeNavMenu: () => {
      dispatch({ type: 'closeNavMenu' });
    },
    toggleNavMenu: () => {
      dispatch({ type: 'toggleNavMenu' });
    },
    openHelpMenu: () => {
      dispatch({ type: 'openHelpMenu' });
    },
    closeHelpMenu: () => {
      dispatch({ type: 'closeHelpMenu' });
    },
    toggleHelpMenu: () => {
      dispatch({ type: 'toggleHelpMenu' });
    },
    closeAllMenus: () => {
      dispatch({ type: 'closeAllMenus' });
    },
  };

  const value = { state, actions };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
