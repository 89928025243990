import { Addr } from '@neo1/core/utils/addressValidation';
import classNames from 'classnames';
import { useAppReferentialSingleton } from 'modules/App/useAppReferential';
import { ReactNode } from 'react';
import styles from './AddressBlock.module.css';

interface AddressLineProps {
  children: ReactNode;
  isBold: boolean;
  fieldName: string;
}

const AddressLine = ({ children, isBold, fieldName }: AddressLineProps) => (
  <div key={fieldName} className={styles.addressLine}>
    <div className={classNames(styles.addressValue, isBold && 'textMBold')}>
      {children}
    </div>
  </div>
);

type Props = {
  address: Addr;
  replacedFields?: string[];
  testId?: string;
};

const AddressBlock = ({ address, replacedFields, testId }: Props) => {
  const { isCountryWithStates, getStateName, getCountry } =
    useAppReferentialSingleton();

  return (
    <div
      className={classNames(styles.addressContainer, 'textM')}
      data-testid={testId}
    >
      <AddressLine
        isBold={
          replacedFields?.includes('streetLine1') ||
          replacedFields?.includes('streetLine2')
        }
        fieldName="streetLine"
      >
        {address.streetLine1}
        {address.streetLine2 && ', '}
        {address.streetLine2}
      </AddressLine>
      <AddressLine
        isBold={
          replacedFields?.includes('city') || replacedFields?.includes('state')
        }
        fieldName="cityState"
      >
        {address.city},{' '}
        {isCountryWithStates(address.countryCode)
          ? getStateName(address.countryCode, address.state)
          : address.state}
      </AddressLine>
      <AddressLine
        isBold={replacedFields?.includes('zipCode')}
        fieldName="zipCode"
      >
        {address.zipCode}
      </AddressLine>
      <AddressLine
        isBold={replacedFields?.includes('countryCode')}
        fieldName="countryCode"
      >
        {getCountry(address.countryCode).name}
      </AddressLine>
    </div>
  );
};

AddressBlock.defaultProps = {
  replacedFields: undefined,
  testId: undefined,
};

export default AddressBlock;
