import { isAdmin } from '@neo1/client/lib/entities/user/utils';
import classNames from 'classnames';
import contextualHelpRoutes from 'config/routes/contextualHelpRoutes';
import genericHelpRoutes from 'config/routes/genericHelpRoutes';
import { AppRoute } from 'config/routes/utils';
import { Events, useAmplitude } from 'contexts/instrumentation';
import { DeviceSize, useWindowContext } from 'contexts/window';
import { isFunction } from 'lodash';
import {
  selectActingCompany,
  selectActingUser,
} from 'modules/Authentification/redux/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import MenuItem from '../MenuItem';
import Contact from './components/Contact';
import messages from './messages';
import styles from './styles.module.css';

const HelpMenu = () => {
  const { pathname } = useLocation();
  const user = useSelector(selectActingUser);
  const company = useSelector(selectActingCompany);
  const intl = useIntl();
  const { deviceSize } = useWindowContext();
  const { logEvent } = useAmplitude();

  const filterRoutes = ({ show }: AppRoute) =>
    isFunction(show) ? show({ pathname, user, company }) : false;

  let routes = contextualHelpRoutes.filter(filterRoutes);
  if (!routes.length) {
    routes = genericHelpRoutes.filter(filterRoutes);
  }

  return (
    <div className={styles.container}>
      <div data-testid="knowledgeBaseLinks">
        <div className={classNames(styles.title, 'header4')}>
          {intl.formatMessage(messages.title)}
        </div>
        {routes.map((route) => (
          <MenuItem
            key={route.name}
            label={route.title?.toString() ?? route.name}
            buttonTitle={intl
              .formatMessage(messages.goToLabel, {
                title: route.title ?? route.name,
              })
              .toString()}
            onClick={() => {
              logEvent({ event: Events.HelpLink, data: { url: route.path } });
              window.open(route.path, '_blank');
            }}
            dark={deviceSize !== DeviceSize.desktop}
          />
        ))}
      </div>
      {isAdmin(user) || company.helpContactName || company.helpContactEmail ? (
        <Contact dark={deviceSize !== DeviceSize.desktop} />
      ) : null}
    </div>
  );
};

export default HelpMenu;
