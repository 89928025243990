import { ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { AuthActions } from 'modules/Authentification/redux/actions';
import { resetState as resetPredefinedState } from 'modules/Reports/Predefined/redux/actions';
import { resetState as resetBuilderState } from 'modules/Reports/Builder/redux/actions';
import { mergeMap } from 'rxjs/operators';

const reports = (action$) =>
  action$.pipe(
    ofType<AnyAction, AuthActions>(
      AuthActions.LOGOUT,
      AuthActions.LOGIN_AS,
      AuthActions.LOGOUT_AS,
    ),
    mergeMap(() => [resetPredefinedState(), resetBuilderState()]),
  );
export default reports;
