import { Placement } from '@popperjs/core';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import ResizerObserver from 'utils/ResizerObserver';
import isEnterOrSpace from 'utils/keyboardEventHelper';
import styles from './Popover.module.css';

type Props = {
  children: ReactNode;
  content: ReactNode;
  className?: string;
  targetClassName?: string;
  testId?: string;
  showOnClick?: boolean;
  placement?: Placement;
  isVisible?: boolean;
  hideArrow?: boolean;
  isInteractive?: boolean;
};

export type Ref = {
  show: () => {};
  hide: () => {};
};

const offset = 0;

const Popover = forwardRef((props: Props, ref) => {
  const {
    children,
    content,
    className,
    targetClassName,
    testId,
    showOnClick,
    placement,
    isVisible,
    hideArrow,
    isInteractive,
  } = props;
  const [mounted, setMounted] = useState(false);
  const targetRef = useRef<HTMLSpanElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [targetRefWidth, setTargetRefWidth] = useState(0);
  useLayoutEffect(() => {
    if (targetRef?.current.getBoundingClientRect) {
      const { width } = targetRef.current.getBoundingClientRect();
      setTargetRefWidth(width);
    }
  }, []);
  const [isPopped, setIsPopped] = useState(false);
  const {
    styles: popperStyles,
    state,
    update,
  } = usePopper(targetRef.current, popperRef.current, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, offset] } },
    ],
  });

  const handleUpdate = debounce(() => {
    if (update && mounted) update();
  }, 10);

  const handleMouseEnter = () => {
    if (!showOnClick) {
      setIsPopped(true);
    }
  };

  const handleMouseLeave = () => {
    if (!showOnClick) {
      setIsPopped(false);
    }
  };

  const handleClick = () => {
    if (showOnClick) {
      setIsPopped(!isPopped);
    }
  };

  const handleClickOutside = () => setIsPopped(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      const referenceElement = targetRef.current;
      ResizerObserver.get().observe(referenceElement, handleUpdate);
      return () => {
        ResizerObserver.get().unobserve(referenceElement);
      };
    }
    return undefined;
  }, [targetRef, handleUpdate]);

  useImperativeHandle(ref, () => ({
    show: () => {
      setIsPopped(true);
    },
    hide: () => {
      setIsPopped(false);
    },
  }));

  const transition = useTransition(isVisible || isPopped, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
  });
  return (
    <>
      <span
        ref={targetRef}
        role="button"
        onClick={handleClick}
        onPointerEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
        onKeyDown={(ev) => {
          if (isEnterOrSpace(ev)) {
            handleClick();
          }
        }}
        className={classNames(styles.ref, targetClassName)}
        tabIndex={showOnClick ? 0 : -1}
        data-testid="popoverTarget"
      >
        {children}
      </span>
      {transition(
        (style, item) =>
          item &&
          createPortal(
            <>
              {showOnClick && (
                /* eslint-disable-next-line jsx-a11y/no-static-element-interactions,  jsx-a11y/click-events-have-key-events */
                <div onClick={handleClickOutside} className={styles.backdrop} />
              )}
              <animated.div
                ref={popperRef}
                onPointerEnter={isInteractive ? handleMouseEnter : () => {}}
                onPointerLeave={isInteractive ? handleMouseLeave : () => {}}
                style={{
                  ...style,
                  ...popperStyles.popper,
                  ...{ minWidth: targetRefWidth },
                }}
                className={classNames(
                  styles.popperWrapper,
                  styles[state?.placement],
                  'textM',
                )}
                data-testid={testId}
              >
                <div
                  className={classNames(
                    className,
                    styles.popper,
                    styles[state?.placement],
                  )}
                >
                  {content}
                  <div
                    ref={setArrowElement}
                    style={popperStyles.arrow}
                    className={classNames(
                      styles.arrow,
                      hideArrow && styles.hideArrow,
                    )}
                  />
                </div>
              </animated.div>
            </>,
            document.body,
          ),
      )}
    </>
  );
});

Popover.defaultProps = {
  className: undefined,
  targetClassName: undefined,
  testId: undefined,
  showOnClick: false,
  placement: 'top',
  isVisible: undefined,
  hideArrow: false,
  isInteractive: true,
};

export default Popover;
