import classNames from 'classnames';
import Icon, { IconName } from 'components/elements/Icon';
import Badge from 'components/elements/Badge';
import styles from './MenuItem.module.css';

type Props = {
  label: string;
  onClick: () => void;
  icon?: IconName;
  buttonTitle?: string;
  selected?: boolean;
  active?: boolean;
  dark?: boolean;
  compact?: boolean;
  counter?: number;
};

const MenuItem = ({
  label,
  onClick,
  icon,
  buttonTitle,
  selected,
  active,
  dark,
  compact,
  counter,
}: Props) => (
  <button
    type="button"
    title={buttonTitle ?? label}
    aria-label={buttonTitle ?? label}
    className={classNames(
      styles.container,
      selected && styles.selected,
      active && styles.active,
      dark && styles.dark,
      compact && styles.compact,
    )}
    onClick={onClick}
  >
    {icon && <Icon name={icon} className={styles.icon} />}
    <div className={classNames(styles.label, compact ? 'textS' : 'textM')}>
      {label}
    </div>
    {counter ? (
      <Badge count={counter} isActive className={styles.counter} />
    ) : null}
  </button>
);

MenuItem.defaultProps = {
  icon: undefined,
  buttonTitle: undefined,
  selected: false,
  active: false,
  dark: false,
  compact: false,
  counter: undefined,
};

export default MenuItem;
