import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_RECONCILIATION } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_RECONCILIATION });

const helpRoutes: AppRoute[] = [
  {
    name: 'spend-how-tos',
    path: 'https://knowledge.neo1.com/support/solutions/categories/47000524533/folders/47000781803',
    title: "Spend How To's",
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'managing-spend-by-user-role',
    path: 'https://knowledge.neo1.com/support/solutions/categories/47000524533/folders/47000427470',
    title: 'Managing Spend (by user role)',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
