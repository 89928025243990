import { UUID_REGEX_AS_STRING } from '@neo1/client';

export const ROUTE_NAME_ADMIN_HOME = 'home';

export const ROUTE_PATH_ADMIN_HOME = '/';

export const ROUTE_PATH_ADMIN_SYSTEM = ROUTE_PATH_ADMIN_HOME;

export const ROUTE_PATH_ADMIN_SYSTEM_ADMINS = `${ROUTE_PATH_ADMIN_SYSTEM}administrators`;

export const ROUTE_PATH_ADMIN_DOMAIN = `${ROUTE_PATH_ADMIN_SYSTEM}domains/:domainId/:companyId(${UUID_REGEX_AS_STRING}|new)?/:tab(main|companyDataChangeRequest)?`;

export const ROUTE_PATH_ADMIN_DOMAIN_ADMINS = `${ROUTE_PATH_ADMIN_DOMAIN}/administrators`;

export const ROUTE_PATH_ADMIN_COMPANY = `${ROUTE_PATH_ADMIN_DOMAIN}/companies/:companyId`;

export const ROUTE_PATH_ADMIN_COMPANY_CHILDREN = `${ROUTE_PATH_ADMIN_COMPANY}/children`;

export const EXCLUDED_USERS_TABLE_COLUMNS = [
  'supervisor',
  'roles',
  'travelerGroup',
];
