import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_PURCHASE_SUPPLIES } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_PURCHASE_SUPPLIES });

const helpRoutes: AppRoute[] = [
  {
    name: 'amazon-business-settings',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001131810',
    title: 'Amazon Business Settings',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'how-to-buy-on-amazon-business-through-neo1',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001131827-how-to-buy-on-amazon-business-through-neo1',
    title: 'How to buy',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'managing-addresses-in-amazon-business',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001131818-managing-addresses-in-amazon-business',
    title: 'Managing Addresses',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'maximise-vat-reclaim-with-amazon-business',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001131820-maximise-vat-reclaim-with-amazon-business',
    title: 'Maximise VAT reclaim',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'how-to-define-a-delivery-address-when-purchasing-on-amazon-business',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001139371-how-to-define-a-delivery-address-when-purchasing-on-amazon-business',
    title: 'How to define a delivery address',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
