import { US_COUNTRY_CODE } from 'components/elements/Google/utils';
import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_SETTINGS } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_SETTINGS });

const helpRoutes: AppRoute[] = [
  {
    name: 'expense-settings',
    path: 'https://knowledge.neo1.com/support/solutions/categories/47000522434/folders/47000781786',
    title: 'Expense Settings',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'user-settings',
    path: 'https://knowledge.neo1.com/support/solutions/categories/47000522434/folders/47000781785',
    title: 'User Settings',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'what-are-virtual-cards',
    path: 'https://knowledge.neo1.com/support/solutions/categories/47000525537/folders/47000785106',
    title: 'What are virtual Cards?',
    show: ({ pathname, company }) =>
      isMatchingRoute(pathname) && company.countryCode === US_COUNTRY_CODE,
  },
  {
    name: 'how-do-i-create-a-virtual-card',
    path: 'https://knowledge.neo1.com/support/solutions/categories/47000525537/folders/47000787112',
    title: 'How do I create a virtual Card?',
    show: ({ pathname, company }) =>
      isMatchingRoute(pathname) && company.countryCode === US_COUNTRY_CODE,
  },
];

export default helpRoutes;
