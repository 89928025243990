import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { SingletonHooksContainer } from 'react-singleton-hook';
// TODO Polyfill of ResizeObserver for Safari older than 13.1, Firefox older than 68, ios-saf older than 13.4 - https://caniuse.com/resizeobserver
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
// TODO Polyfill of IntersectionObserver for Safari older than 12.1 - https://caniuse.com/intersectionobserver
import { RpcClientError, RpcErrorCode, RpcPayload } from '@neo1/client';
import { refreshToken } from '@neo1/client/lib/entities/auth/api';
import { parseJwt } from '@neo1/client/lib/entities/auth/utils';
import {
  getClient,
  getPublicClient,
  getSafeClient,
} from '@neo1/client/lib/rpc/client';
import { configureFileEndpoints, fetchJson } from '@neo1/core/utils/files';
import Notifications from 'components/layout/App/Notifications';
import OneTrust from 'components/layout/App/OneTrust';
import ErrorBoundary from 'components/layout/ErrorBoundary';
import FullPageError from 'components/layout/ErrorBoundary/FullPageError';
import appConfig, { getConfigValue, override as overrideConfig } from 'config';
import configureI18n from 'config/i18n';
import { DEFAULT_LOCALE } from 'config/i18n/constants';
import { initializeAmplitudeInstance } from 'contexts/instrumentation/amplitude';
import { initialize as initializeGtm } from 'contexts/instrumentation/gtm';
import dayjs from 'dayjs';
import 'intersection-observer';
import { notifyWarning } from 'modules/App/redux/notifications/toaster/thunks';
import { launchUpdate } from 'modules/App/redux/update/actions';
import { setToken } from 'modules/Authentification/redux/actions';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import {
  logout,
  resume as resumeSession,
} from 'modules/Authentification/redux/thunks';
import { reconnectExtractService } from 'redux/company/thunks';
import { interval } from 'rxjs';
import CssVars from 'styles/CssVars';
import { getLatestVersion } from 'utils/version';
import initCloudwatchRum from './initCloudwatchRum';
import {
  detectAccountingServiceOauthRedirection,
  getAccountingOAuthCallbackUrl,
} from './oauth';
import { BootstrapContext, BootstrapTask } from './types';

declare let NEO1_UI_TECHNICAL_VERSION: any;
declare let module: any;

if (!window.ResizeObserver) {
  window.ResizeObserver = Polyfill;
}

async function initApiClient({ store, history }: BootstrapContext) {
  // Create a JSON RPC api client
  const apiClient = getClient({
    clientType: 'ui',
    neo1Version: NEO1_UI_TECHNICAL_VERSION,
    baseUrl: appConfig.apiRpc,
  });

  const safeApiClient = getSafeClient();

  // Set safe client base url from config
  safeApiClient.setConfigKey('baseUrl', appConfig.safeApiRpc);

  const publicClient = getPublicClient();

  if (process.env.NODE_ENV === 'development' || window.debugMode) {
    apiClient.setConfigKey('debug', true);
    safeApiClient.setConfigKey('debug', true);
    publicClient.setConfigKey('debug', true);
  }

  const forceLogout = async () => {
    await store.dispatch<any>(logout(true));
    history.replace('/');
  };

  async function requestInterceptor(payload: RpcPayload) {
    const authToken = apiClient.getConfigKey('authToken');
    if (
      authToken &&
      (!('method' in payload) ||
        ('method' in payload &&
          payload.method !== 'refresh_token' &&
          payload.method !== 'logout'))
    ) {
      const { exp } = parseJwt(authToken);
      const now = dayjs();
      if (now.unix() > exp) {
        // if the token has expired, logout
        await forceLogout();
        // and "cancelling" this request.
        throw new Error('Your session has expired, please log in again');
      } else if (dayjs.unix(exp).diff(now, 'minute', true) < 180) {
        // refresh the user token if not expired but will expire in 3 hours
        const newToken = await refreshToken();
        store.dispatch(setToken(newToken));
        apiClient.setConfigKey('authToken', newToken);
        safeApiClient.setConfigKey('authToken', newToken);
      }
    }
  }

  apiClient.requestInterceptor = requestInterceptor;
  safeApiClient.requestInterceptor = requestInterceptor;

  // TODO refactor this part by adding a reponseInterceptor in apiClient DEV-7335
  getClient().on('error', (err: RpcClientError) => {
    if (err.httpStatus === 401) {
      forceLogout();
    } else if (err.code === RpcErrorCode.ExternalServiceFailure) {
      const currentCompany = selectActingCompany(store.getState());

      if (currentCompany) {
        store.dispatch(
          notifyWarning(
            'Granted access to your accounting system has expired or has been revoked. ' +
              'Please connect again to resume the extract process.',
            {
              label: 'Reconnect accounting system',
              onClick: () => {
                store
                  .dispatch<any>(
                    reconnectExtractService(
                      currentCompany.id,
                      getAccountingOAuthCallbackUrl(
                        currentCompany.accountingLink,
                        history.location.pathname,
                      ),
                    ),
                  )
                  .then(({ url }: any) => {
                    window.location.href = url;
                  });
              },
            },
          ),
        );
      }
    }
  });
}

/**
 * Initializes session from storage data
 * Loads from storage tokens that will enable to resume the session
 */
async function restoreAuthFromStorage(context: BootstrapContext) {
  const { store } = context;
  if (!store.getState().auth.authToken) return;
  try {
    await store.dispatch<any>(resumeSession());
  } catch (error) {
    store.dispatch<any>(logout());
  }
}

/**
 * Catches an OAuth redirection to initialize the state properly
 * @param {*} param0
 */
async function detectOAuthRedirection(ctx: BootstrapContext) {
  await detectAccountingServiceOauthRedirection(ctx);
  return ctx;
}

/**
 * Fetch env config file and override config if necessary
 * @param {*} context
 */
async function initAppConfig() {
  configureFileEndpoints({
    apiFilesBaseUrl: appConfig.apiFiles,
    localesBaseUrl: appConfig.localesContextPath,
  });

  let config;

  await configureI18n(DEFAULT_LOCALE);

  try {
    config = await fetchJson('/config.json');
  } catch (error) {
    return;
  }

  overrideConfig(config);

  // initializes Amplitude instance with the API key, just after the config override.
  initializeAmplitudeInstance(getConfigValue('amplitudeAPIKey'));

  initializeGtm(getConfigValue('gtmId'));
}

/**
 * Renders application into the dom
 * @param {object} appContext application context
 */
function render({ container, store, history }: BootstrapContext) {
  // the require done here prevents a wierd error form happening while running tests.
  // eslint-disable-next-line global-require
  const AppRoot = require('modules/App').default;

  const root = createRoot(container);

  root.render(
    <>
      <CssVars />
      <ErrorBoundary renderComponent={() => <FullPageError />}>
        <ReduxProvider store={store as any}>
          <>
            <OneTrust />
            <SingletonHooksContainer />
            <AppRoot history={history} />
            <Notifications />
          </>
        </ReduxProvider>
      </ErrorBoundary>
    </>,
  );
}

/**
 * Polls CDN to check for new UI version
 */
function startLatestVersionPoll({ store }: BootstrapContext) {
  if (getConfigValue('pollVersionInterval')) {
    const notifyNewVersion = () => {
      store.dispatch(launchUpdate());
    };

    interval(getConfigValue('pollVersionInterval')).subscribe(async () => {
      const {
        app: { update },
      } = store.getState();
      const latestVersion = await getLatestVersion();
      if (
        latestVersion &&
        NEO1_UI_TECHNICAL_VERSION !== latestVersion &&
        !update.isUpdateLaunched
      ) {
        notifyNewVersion();
      }
    });
  }
}

/**
 * Configures webpack hot reloading in debug mode
 */
function startHotReloading(appContext: BootstrapContext) {
  if (module.hot) {
    module.hot.accept('modules/App', () => render(appContext));
  }
}

const BOOTSTRAP_SEQUENCE: Record<string, BootstrapTask> = {
  initAppConfig,
  initApiClient,
  initCloudwatchRum,
  restoreAuthFromStorage,
  detectOAuthRedirection,
  startLatestVersionPoll,
  startHotReloading,
  render,
};

/**
 * UI boostrap sequence
 * @param {*} ctx
 */
async function bootstrap(ctx: BootstrapContext) {
  /* eslint-disable no-restricted-syntax, no-await-in-loop */
  for (const [, run] of Object.entries(BOOTSTRAP_SEQUENCE)) {
    await run(ctx);
  }
  return ctx;
}

export default bootstrap;

export * from './oauth';
