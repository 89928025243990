import { isAdmin } from '@neo1/client/lib/entities/user/utils';
import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_SPEND } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_SPEND });

const helpRoutes: AppRoute[] = [
  {
    name: 'expense-submit-manage',
    path: 'https://knowledge.neo1.com/support/solutions/47000524533',
    title: 'Expense submit & manage',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'what-is-the-expense-timeline',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000543710-what-is-the-expense-timeline',
    title: 'What is the Expense Timeline?',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && !isAdmin(user),
  },
  {
    name: 'how-to-approve-an-expense-line-transaction',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001253468-how-to-approve-an-expense-line-transaction',
    title: 'How to approve an expense line (transaction)',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && isAdmin(user),
  },
  {
    name: 'spend-management-for-end-users',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000614052-spend-management-for-end-users',
    title: 'Spend Management',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'how-to-claim-mileage',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001134908-how-to-claim-mileage',
    title: 'How to Claim Mileage',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && !isAdmin(user),
  },
  {
    name: 'reconciliation-running-extracts-from-neo1',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001134436-reconciliation-running-extracts-from-neo1',
    title: 'Reconciliation',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && isAdmin(user),
  },
  {
    name: 'where-can-i-find-extract-files',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000543765-where-can-i-find-extract-files',
    title: 'Where can I find extract files?',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && isAdmin(user),
  },
  {
    name: 'how-to-create-and-group-transactions',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001234401-how-to-create-and-group-transactions',
    title: 'How to create transactions',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && !isAdmin(user),
  },
  {
    name: 'how-to-delete-transactions',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001269723-how-to-delete-transactions',
    title: 'How to delete transactions',
    show: ({ pathname, user }) => isMatchingRoute(pathname) && !isAdmin(user),
  },
];

export default helpRoutes;
