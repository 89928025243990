import { useLayoutEffect, useState, useRef, ReactNode } from 'react';
import classNames from 'classnames';
import LoaderObserver from 'utils/LoaderObserver';
import LoadPlaceHolder from 'components/elements/LoadPlaceholder';
import styles from './TableCell.module.css';

type Props = {
  children: ReactNode;
  className?: string;
  colSpan?: number;
  width?: string;
  fixed?: 'left' | 'right';
  fixedOffset?: string;
  showBorderLeft?: boolean;
  showBorderRight?: boolean;
};

const TableCell = ({
  children,
  className,
  colSpan,
  width,
  fixed,
  fixedOffset,
  showBorderLeft,
  showBorderRight,
}: Props) => {
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const showContent = isVisible || process.env.NODE_ENV === 'test';

  const handleGettingVisible = () => {
    setVisible(true);
  };

  useLayoutEffect(() => {
    const element = ref.current;
    LoaderObserver.get().observe(element, handleGettingVisible);
    return () => {
      LoaderObserver.get().unobserve(element);
    };
  }, []);

  return (
    <td
      ref={ref}
      className={classNames(
        className,
        showBorderLeft && styles.withBorderLeft,
        showBorderRight && styles.withBorderRight,
      )}
      colSpan={colSpan}
      style={{
        width,
        position: fixed ? 'sticky' : undefined,
        left: fixed === 'left' ? fixedOffset : undefined,
        right: fixed === 'right' ? fixedOffset : undefined,
        zIndex: fixed ? 1 : undefined,
      }}
      data-testid="tableCell"
    >
      {showContent ? children : <LoadPlaceHolder className={styles.load} />}
    </td>
  );
};

TableCell.defaultProps = {
  className: undefined,
  colSpan: undefined,
  width: undefined,
  fixed: undefined,
  fixedOffset: 0,
  showBorderLeft: false,
  showBorderRight: false,
};

export default TableCell;
