import { defineMessages } from 'react-intl';

const messages = defineMessages({
  nameLabel: {
    id: 'App_HelpMenu_ContactForm_nameLabel',
    defaultMessage: 'Contact name',
  },
  emailLabel: {
    id: 'App_HelpMenu_ContactForm_emailLabel',
    defaultMessage: 'Help Contact email address',
  },
  emailError: {
    id: 'App_HelpMenu_ContactForm_emailError',
    defaultMessage: 'Enter a valid email, eg. john.doe@neo1.com',
  },
  editModalTitle: {
    id: 'App_HelpMenu_ContactForm_editModalTitle',
    defaultMessage: 'Edit help contact information',
  },
  addModalTitle: {
    id: 'App_HelpMenu_ContactForm_addModalTitle',
    defaultMessage: 'Add help contact information',
  },
});

export default messages;
