import { isAdmin } from '@neo1/client/lib/entities/user/utils';
import classNames from 'classnames';
import Button, { ButtonTheme } from 'components/elements/Button';
import Link, { LinkFontSize } from 'components/elements/Link';
import {
  selectActingCompany,
  selectActingUser,
} from 'modules/Authentification/redux/selectors';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ContactModalForm from '../ContactForm';
import styles from './styles.module.css';
import messages from './messages';

type Props = {
  dark?: boolean;
};

const Contact = ({ dark }: Props) => {
  const user = useSelector(selectActingUser);
  const [showModal, setShowModal] = useState(false);
  const company = useSelector(selectActingCompany);
  const hasHelpContact = company.helpContactName || company.helpContactEmail;
  const intl = useIntl();

  return (
    <div
      className={classNames(styles.container, dark && styles.dark)}
      data-testid="contactBlock"
    >
      {hasHelpContact ? (
        <>
          <span className="textS">
            {intl.formatMessage(messages.contactAvailableMessage)}
          </span>
          <div className={classNames(styles.contact, 'textSSemibold')}>
            <strong>{company.helpContactName}</strong>
            <Link
              to={{ pathname: `mailto:${company.helpContactEmail}` }}
              target="_top"
              fontSize={LinkFontSize.S}
              bold
            >
              {company.helpContactEmail}
            </Link>
          </div>
        </>
      ) : (
        <span className="textS">
          {intl.formatMessage(messages.contactToFillMessage)}
        </span>
      )}

      {isAdmin(user) && (
        <Button
          label={
            hasHelpContact
              ? intl.formatMessage(messages.editContactBtn)
              : intl.formatMessage(messages.addContactBtn)
          }
          onClick={() => setShowModal(true)}
          theme={ButtonTheme.Outlined}
          dark={dark}
        />
      )}

      <ContactModalForm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

Contact.defaultProps = {
  dark: false,
};

export default Contact;
