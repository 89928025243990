import { AppRoute } from 'config/routes/utils';
import { matchPath } from 'react-router';
import { ROUTE_PATH_RECEIPTS } from './constants';

const isMatchingRoute = (pathname: string) =>
  !!matchPath(pathname, { path: ROUTE_PATH_RECEIPTS });

const helpRoutes: AppRoute[] = [
  {
    name: 'capture-receipts-with-neo1-mobile-app',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47001161893-neo1-mobile-app-receipt-capture-for-spend-management',
    title: 'Capture receipts with Neo1 mobile app',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
  {
    name: 'upload-receipts-with-neo1-web-app',
    path: 'https://knowledge.neo1.com/support/solutions/articles/47000614052-spend-management-for-end-users#Digital-receipts-&-OCR',
    title: 'Upload receipts with Neo1 web app',
    show: ({ pathname }) => isMatchingRoute(pathname),
  },
];

export default helpRoutes;
